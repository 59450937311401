var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { value: _vm.allValid },
      on: {
        input: function ($event) {
          return _vm.$emit("update:all-valid", $event)
        },
      },
    },
    [
      _c(
        "m-container",
        { staticClass: "pa-0" },
        [
          _c(
            "m-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "m-col",
                { staticClass: "pa-0 mt-6" },
                [
                  _c("m-text-field", {
                    attrs: {
                      outlined: "",
                      label: !_vm.$language ? "Title" : "শিরোনাম",
                      placeholder: !_vm.$language
                        ? "Enter your exam title here..."
                        : "পরীক্ষার শিরোনাম লিখুন...",
                      height: "45",
                      value: _vm.formData.title,
                      rules: _vm.rules.title,
                      "data-qa": "title",
                    },
                    on: { change: (v) => (_vm.formData.title = v) },
                  }),
                ],
                1
              ),
              _c(
                "m-col",
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { height: "100%" },
                      attrs: { "no-gutters": "", "align-center": "" },
                    },
                    [
                      _c(
                        "m-col",
                        { staticClass: "d-flex justify-center align-center" },
                        [
                          _c("p", { staticClass: "xl:text-lg" }, [
                            _vm._v("Split view:  "),
                          ]),
                          _c("img", {
                            staticClass: "clickable",
                            attrs: {
                              width: "69",
                              src: require(_vm.isSplitView
                                ? "#ecf/exam/assets/Toggle-active.svg"
                                : "#ecf/exam/assets/Toggle-inactive.svg"),
                            },
                            on: { click: _vm.toggleSplitView },
                          }),
                        ]
                      ),
                      _c(
                        "m-col",
                        { staticClass: "d-flex justify-end align-center" },
                        [
                          _c("p", { staticClass: "xl:text-lg" }, [
                            _vm._v("Practice exam:  "),
                          ]),
                          _c("img", {
                            staticClass: "clickable",
                            attrs: {
                              width: "69",
                              src: require(_vm.isPracticeExam
                                ? "#ecf/exam/assets/Toggle-active.svg"
                                : "#ecf/exam/assets/Toggle-inactive.svg"),
                            },
                            on: { click: _vm.togglePracticeExam },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "m-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "m-col",
                { staticClass: "pa-0" },
                [
                  _c("m-textarea", {
                    attrs: {
                      outlined: "",
                      "auto-grow": "",
                      clearable: "",
                      "clearable-icon": "close",
                      label: !_vm.$language ? "Instruction" : "নির্দেশনা",
                      counter: "1024",
                      maxlenght: "1024",
                      rules: _vm.rules.instruction,
                      placeholder: !_vm.$language
                        ? "Enter your exam instructions here..."
                        : "পরীক্ষার নির্দেশনা দিন...",
                      value: _vm.formData.instruction,
                      "data-test": "exam-text",
                    },
                    on: { change: (v) => (_vm.formData.instruction = v) },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "m-col",
                { attrs: { cols: "5" } },
                [
                  _c("m-text-field", {
                    attrs: {
                      outlined: "",
                      label: !_vm.$language ? "Duration" : "সময়কাল",
                      placeholder: !_vm.$language
                        ? "Enter your exam duration here..."
                        : "পরীক্ষার সময়কাল লিখুন",
                      height: "45",
                      rules: _vm.rules.examDurationRule,
                      value: _vm.formData.duration,
                      "data-qa": "duration",
                    },
                    on: { change: (v) => (_vm.formData.duration = v) },
                  }),
                ],
                1
              ),
              _c(
                "m-col",
                { attrs: { cols: "5" } },
                [
                  _c("m-text-field", {
                    attrs: {
                      outlined: "",
                      label: !_vm.$language ? "Points" : "পয়েন্টস",
                      placeholder: !_vm.$language
                        ? "Enter your exam points here..."
                        : "পরীক্ষার পয়েন্টস লিখুন",
                      height: "45",
                      rules: _vm.rules.examTotalPointsRule,
                      value: _vm.formData.points,
                      "data-qa": "points",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.$emit("totalPointsChange")
                      },
                      change: (v) => (_vm.formData.points = v),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }