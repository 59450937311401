var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    { staticClass: "pa-0" },
    [
      _c(
        "div",
        { staticClass: "pb-4", staticStyle: { "max-width": "1100px" } },
        [
          _c("submission-alert-message", {
            ref: "banner",
            attrs: {
              "should-scroll-to-top": "",
              message: !_vm.sectionDurationMissingMessage
                ? _vm.badExamFormMessage[_vm.$language]
                : _vm.sectionDurationMissingMessage,
            },
            model: {
              value: _vm.showAlertCondition,
              callback: function ($$v) {
                _vm.showAlertCondition = $$v
              },
              expression: "showAlertCondition",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "m-light-card pa-6 border-default-primary-solid" },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.currentStep,
                callback: function ($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            [
              _c(
                "v-tab",
                {
                  staticClass: "single-tab-item xl:text-xl lg:text-xl",
                  attrs: { "active-class": "selected-tab" },
                },
                [
                  _vm._v(
                    " " + _vm._s(!_vm.$language ? "Details" : "বিস্তারিত") + " "
                  ),
                ]
              ),
              _c(
                "v-tab",
                {
                  staticClass: "single-tab-item xl:text-xl lg:text-xl",
                  attrs: { "active-class": "selected-tab" },
                },
                [
                  _vm._v(
                    " " + _vm._s(!_vm.$language ? "Questions" : "প্রশ্ন") + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("hr", { staticClass: "hr-style" }),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.currentStep,
                callback: function ($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c("exam-details-form", {
                    ref: "details",
                    attrs: {
                      "all-valid": _vm.examDetailsOk,
                      "data-qa": "exam-details-form",
                    },
                    on: {
                      "update:allValid": function ($event) {
                        _vm.examDetailsOk = $event
                      },
                      "update:all-valid": function ($event) {
                        _vm.examDetailsOk = $event
                      },
                      "total-points-change": (v) => {
                        _vm.examData.examDetails.points = v
                      },
                    },
                    model: {
                      value: _vm.examData["examDetails"],
                      callback: function ($$v) {
                        _vm.$set(_vm.examData, "examDetails", $$v)
                      },
                      expression: "examData['examDetails']",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text xl:text-lg mb-1",
                      attrs: { color: "primary", width: "86", height: "46" },
                      on: { click: _vm.nextStep },
                    },
                    [_vm._v(" Next ")]
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c("exam-questions-form", {
                    attrs: { "total-points": _vm.examData.examDetails.points },
                    on: {
                      "update:totalPoints": function ($event) {
                        return _vm.$set(
                          _vm.examData.examDetails,
                          "points",
                          $event
                        )
                      },
                      "update:total-points": function ($event) {
                        return _vm.$set(
                          _vm.examData.examDetails,
                          "points",
                          $event
                        )
                      },
                      match: _vm.updatePointsMatch,
                    },
                    model: {
                      value: _vm.examData.questions,
                      callback: function ($$v) {
                        _vm.$set(_vm.examData, "questions", $$v)
                      },
                      expression: "examData.questions",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4 mb-1",
                      attrs: { outlined: "" },
                      on: { click: _vm.previousStep },
                    },
                    [_vm._v(" Back ")]
                  ),
                  _vm.examData.questions !== undefined &&
                  _vm.examData.questions.length > 0
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mt-4 ml-4 mb-1 white--text",
                          attrs: { color: "primary" },
                          on: { click: _vm.save },
                        },
                        [
                          _vm.creating
                            ? _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "white" },
                              })
                            : _c("p", [_vm._v("Save")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }