var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.orderedIeltsQuestions
        ? _c("v-simple-table", [
            _c("thead", [
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(!_vm.$language ? "Question" : "প্রশ্ন")),
                ]),
                _c("th", [_vm._v(_vm._s(!_vm.$language ? "Type" : "টাইপ"))]),
                _c("th", [
                  _vm._v(_vm._s(!_vm.$language ? "Points" : "পয়েন্ট")),
                ]),
                !_vm.readonly
                  ? _c("th", [
                      _vm._v(_vm._s(!_vm.$language ? "Actions" : "পরিবর্তন")),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm.questions
              ? _c(
                  "tbody",
                  _vm._l(_vm.orderedQuestions, function (question, idx) {
                    return _c("tr", { key: question.question_id }, [
                      _c("td", [
                        question.questionDetails.text
                          ? _c("div", {
                              staticClass: "ml-4 py-2",
                              staticStyle: {
                                overflow: "hidden",
                                height: "max-content",
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.format(question.questionDetails.text)
                                ),
                              },
                            })
                          : question.questionDetails.file_url
                          ? _c(
                              "div",
                              [
                                _c("m-img", {
                                  attrs: {
                                    src: question.questionDetails.file_url,
                                    "max-width": "150",
                                    "max-height": "150",
                                    contain: "",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      question.essay_id != null
                        ? _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$language
                                    ? "Group Question"
                                    : "গ্রুপ প্রশ্ন"
                                ) +
                                " "
                            ),
                          ])
                        : _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$language
                                    ? "Single Question"
                                    : "সিঙ্গেল প্রশ্ন"
                                ) +
                                " "
                            ),
                          ]),
                      _c(
                        "td",
                        [
                          !_vm.readonly
                            ? _c("v-text-field", {
                                attrs: {
                                  value: question.points,
                                  width: "120px",
                                  label: "points",
                                  type: "text",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.addPoints($event, idx)
                                  },
                                },
                              })
                            : _c("span", [_vm._v(_vm._s(question.points))]),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          !_vm.readonly
                            ? _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteCategory(idx)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      !_vm.$language ? "Delete" : "ডিলিট"
                                    ) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._t("rowActions"),
                        ],
                        2
                      ),
                    ])
                  }),
                  0
                )
              : _vm.ieltsQuestions
              ? _c(
                  "tbody",
                  _vm._l(_vm.orderedIeltsQuestions, function (question, idx) {
                    return _c("tr", { key: question.essay_id }, [
                      question.question_group
                        ? _c(
                            "td",
                            [
                              _c("question-group-viewer", {
                                staticClass: "pa-2",
                                attrs: {
                                  content: question.question_group.item_text,
                                  questions: question.questions,
                                  answers: question.answers,
                                  files: question.files,
                                  readonly: true,
                                },
                              }),
                            ],
                            1
                          )
                        : _c("td", [
                            question.questionDetails.text
                              ? _c("div", {
                                  staticClass: "ml-4 py-2",
                                  staticStyle: {
                                    overflow: "hidden",
                                    height: "max-content",
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.format(question.questionDetails.text)
                                    ),
                                  },
                                })
                              : question.questionDetails.file_url
                              ? _c(
                                  "div",
                                  [
                                    _c("m-img", {
                                      attrs: {
                                        src: question.questionDetails.file_url,
                                        "max-width": "150",
                                        "max-height": "150",
                                        contain: "",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                      question.essay_id != null
                        ? _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$language
                                    ? "Group Question"
                                    : "গ্রুপ প্রশ্ন"
                                ) +
                                " "
                            ),
                          ])
                        : _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$language
                                    ? "Single Question"
                                    : "সিঙ্গেল প্রশ্ন"
                                ) +
                                " "
                            ),
                          ]),
                      question.question_group
                        ? _c(
                            "td",
                            _vm._l(
                              question.questions,
                              function (questin, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    !_vm.readonly
                                      ? _c("v-text-field", {
                                          attrs: {
                                            value: questin.points,
                                            width: "120px",
                                            label: "points",
                                            type: "text",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.addPoints(
                                                $event,
                                                idx,
                                                index
                                              )
                                            },
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(questin.points)),
                                        ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        : _c(
                            "td",
                            [
                              !_vm.readonly
                                ? _c("v-text-field", {
                                    attrs: {
                                      value: question.points,
                                      width: "120px",
                                      label: "points",
                                      type: "text",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.addPoints($event, idx)
                                      },
                                    },
                                  })
                                : _c("span", [_vm._v(_vm._s(question.points))]),
                            ],
                            1
                          ),
                      _c(
                        "td",
                        [
                          !_vm.readonly
                            ? _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteCategory(idx)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      !_vm.$language ? "Delete" : "ডিলিট"
                                    ) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._t("rowActions"),
                        ],
                        2
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }