var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    {
      staticClass: "d-flex align-center",
      staticStyle: { "min-height": "70vh" },
    },
    [
      _c(
        "m-container",
        [
          _c("h1", { staticClass: "mb-5 page-title" }, [
            _vm._v(
              " " +
                _vm._s(!_vm.$language ? "Add Questions" : "প্রশ্ন যোগ করুন") +
                " "
            ),
          ]),
          _c("question-list", {
            attrs: {
              selectable: true,
              selected: _vm.questions,
              "category-search": true,
              "hide-actions": "",
            },
            on: {
              "update:selected": function ($event) {
                _vm.questions = $event
              },
            },
          }),
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        color: "red accent-3",
                        "min-width": "100",
                      },
                      on: { click: _vm.close },
                    },
                    [
                      _vm._v(
                        _vm._s(!_vm.$language ? "Cancel" : "বাতিল করুন") + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3",
                      attrs: {
                        depressed: "",
                        color: "primary",
                        "min-width": "100",
                      },
                      on: { click: _vm.save },
                    },
                    [_vm._v(_vm._s(!_vm.$language ? "Save" : "সেভ করুন") + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }