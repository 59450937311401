<template>
  <v-content class="pa-0">
    <v-container fluid justify-center class="pa-0">
      <v-flex class="elevation-0 pa-0 ma-0">
        <v-list
          two-line
          class="pa-0 mt-6"
          style="min-height: 300px; overflow: hidden; scroll-behavior: smooth"
        >
          <draggable v-model="sections">
            <template v-for="(section, i) in sections">
              <exam-section
                :key="i"
                :form-data="section"
                @onDeleteSection="remove(i)"
                @onEditSection="edit(i)"
                @getSectionTotalPoints="calculatePoints"
              >
              </exam-section>
            </template>
          </draggable>
        </v-list>
      </v-flex>
    </v-container>
  </v-content>
</template>

<script>
import draggable from "vuedraggable";
import ExamSection from "#ecf/exam/components/ExamSection.vue";

export default {
  name: "ExamSectionList",
  components: { ExamSection, draggable },
  props: ["sections"],
  data() {
    return {
      before: null,
      editing: false,
      isExpandSection: false,
      totalExamPoints: 0
    };
  },
  computed: {
    options() {
      return {
        disabled: !this.editing
      };
    }
  },
  methods: {
    edit(i) {
      this.$emit("editSection", i);
    },
    remove(i) {
      this.sections.splice(i, 1);
      this.$emit("update", this.sections);
    },
    calculatePoints() {
      this.$emit("update", this.sections);
      this.$emit("getTotalExamPoints");
    }
  }
};
</script>

<style>
#drag {
  margin: 40px;
}

.draggable-flex {
  display: inherit;
  flex-direction: inherit;
}
</style>
