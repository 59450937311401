var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        [
          _c(
            "v-expansion-panel",
            [
              _c(
                "v-expansion-panel-header",
                { staticStyle: { border: "#b7b7b7 1px solid" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: { height: "60px" },
                          attrs: { "no-gutters": "", align: "center" },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-start align-center",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("#ecf/exam/assets/moveable.svg"),
                                },
                              }),
                              _vm._v("  " + _vm._s(_vm.formData.title) + " "),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-end align-center" },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      on: { click: _vm.editSection },
                                    },
                                    [_vm._v("mdi-pencil-outline")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "red" },
                                      on: { click: _vm.deleteSection },
                                    },
                                    [_vm._v("mdi-delete")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                { staticStyle: { border: "#b7b7b7 1px solid" } },
                [
                  _c("exam-questions-table", {
                    attrs: { "ielts-questions": _vm.formData.questions },
                    on: {
                      "add-points": _vm.addPoints,
                      "validate-marks": _vm.validateMarks,
                      delete: _vm.deleteCategory,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }