var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    {
      staticClass:
        "mt-6 pt-2 px-4 border-default-primary-solid border-radius-5",
      staticStyle: { "min-height": "450px" },
    },
    [
      _c(
        "m-row",
        { staticClass: "pb-2", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "ml-7 d-flex justify-end" },
            [
              !_vm.isQuestionAdded
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "primary" },
                      on: { click: _vm.showSectionPicker },
                    },
                    [
                      _c("m-icon", { staticClass: "mr-1" }, [_vm._v("add ")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            !_vm.$language ? "Add Sections" : "প্রশ্ন যোগ করুন"
                          ) +
                          " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isSectionAdded
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text ml-4",
                      attrs: { color: "primary" },
                      on: { click: _vm.showQuestionPicker },
                    },
                    [
                      _c("m-icon", { staticClass: "mr-1" }, [_vm._v("add ")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            !_vm.$language ? "Add Questions" : "প্রশ্ন যোগ করুন"
                          ) +
                          " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr", { staticClass: "hr-style" }),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          !_vm.isSectionAdded
            ? _c("exam-questions-table", {
                attrs: { "ielts-questions": _vm.formData.questions },
                on: {
                  "add-points": _vm.addPoints,
                  "validate-marks": _vm.validateMarks,
                  delete: _vm.deleteCategory,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isSectionAdded
        ? _c("exam-section-list", {
            attrs: { sections: _vm.sections },
            on: {
              update: _vm.updateSectionList,
              editSection: _vm.editASection,
              getTotalExamPoints: _vm.validateSectionMarks,
            },
          })
        : _vm._e(),
      _vm._t("actions"),
      _c(
        "v-dialog",
        {
          attrs: { width: "70%" },
          model: {
            value: _vm.isSectionQuestionPickerVisible,
            callback: function ($$v) {
              _vm.isSectionQuestionPickerVisible = $$v
            },
            expression: "isSectionQuestionPickerVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: { click: _vm.hideSectinQuestionPicker },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("question-picker", {
                    on: { cancel: _vm.hideSectinQuestionPicker },
                    model: {
                      value: _vm.sectionData.questions,
                      callback: function ($$v) {
                        _vm.$set(_vm.sectionData, "questions", $$v)
                      },
                      expression: "sectionData.questions",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          key: _vm.questionPickerKey,
          attrs: { width: "70%" },
          model: {
            value: _vm.isQuestionPickerVisible,
            callback: function ($$v) {
              _vm.isQuestionPickerVisible = $$v
            },
            expression: "isQuestionPickerVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: { click: _vm.hideCategoryForm },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("question-picker", {
                    on: {
                      input: _vm.validateMarks,
                      cancel: _vm.hideCategoryForm,
                    },
                    model: {
                      value: _vm.formData.questions,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "questions", $$v)
                      },
                      expression: "formData.questions",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          key: _vm.sectionPickerKye,
          attrs: { width: "70%" },
          model: {
            value: _vm.isSectionPickerVisible,
            callback: function ($$v) {
              _vm.isSectionPickerVisible = $$v
            },
            expression: "isSectionPickerVisible",
          },
        },
        [
          _c("exam-section-picker", {
            attrs: { questions: _vm.sectionData.questions, "is-edit": false },
            on: {
              pickSectionQuestions: _vm.getSectionQuestions,
              cancel: _vm.hideSectinPicker,
              saveSection: _vm.saveASection,
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          key: _vm.editSectionPickerKye,
          attrs: { width: "70%" },
          model: {
            value: _vm.isEditSectionPickerVisible,
            callback: function ($$v) {
              _vm.isEditSectionPickerVisible = $$v
            },
            expression: "isEditSectionPickerVisible",
          },
        },
        [
          _c("exam-section-picker", {
            attrs: {
              questions: _vm.sectionData.questions,
              "existing-data": _vm.sectionData,
              "is-edit": true,
            },
            on: {
              pickSectionQuestions: _vm.getSectionQuestions,
              cancel: _vm.hideSectinPicker,
              updateSection: _vm.updateASection,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }