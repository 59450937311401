<template>
  <m-container
    style="min-height: 450px"
    class="mt-6 pt-2 px-4 border-default-primary-solid border-radius-5"
  >
    <m-row no-gutters class="pb-2">
      <v-col class="ml-7 d-flex justify-end">
        <v-btn
          v-if="!isQuestionAdded"
          color="primary"
          class="white--text"
          @click="showSectionPicker"
        >
          <m-icon class="mr-1">add </m-icon>
          {{ !$language ? "Add Sections" : "প্রশ্ন যোগ করুন" }}
        </v-btn>
        <v-btn
          v-if="!isSectionAdded"
          color="primary"
          class="white--text ml-4"
          @click="showQuestionPicker"
        >
          <m-icon class="mr-1">add </m-icon>
          {{ !$language ? "Add Questions" : "প্রশ্ন যোগ করুন" }}
        </v-btn>
      </v-col>
    </m-row>
    <hr class="hr-style" />
    <v-card flat>
      <exam-questions-table
        v-if="!isSectionAdded"
        :ielts-questions="formData.questions"
        @add-points="addPoints"
        @validate-marks="validateMarks"
        @delete="deleteCategory"
      ></exam-questions-table>
    </v-card>

    <exam-section-list
      v-if="isSectionAdded"
      :sections="sections"
      @update="updateSectionList"
      @editSection="editASection"
      @getTotalExamPoints="validateSectionMarks"
    ></exam-section-list>

    <slot name="actions"></slot>
    <!-- block for get questions with section -->
    <v-dialog v-model="isSectionQuestionPickerVisible" width="70%">
      <v-card>
        <v-card-title class="justify-end">
          <v-btn icon @click="hideSectinQuestionPicker">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <question-picker
            v-model="sectionData.questions"
            @cancel="hideSectinQuestionPicker"
          ></question-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- block for get question without section -->
    <v-dialog
      :key="questionPickerKey"
      v-model="isQuestionPickerVisible"
      width="70%"
    >
      <v-card>
        <v-card-title class="justify-end">
          <v-btn icon @click="hideCategoryForm">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <question-picker
            v-model="formData.questions"
            @input="validateMarks"
            @cancel="hideCategoryForm"
          ></question-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      :key="sectionPickerKye"
      v-model="isSectionPickerVisible"
      width="70%"
    >
      <exam-section-picker
        :questions="sectionData.questions"
        :is-edit="false"
        @pickSectionQuestions="getSectionQuestions"
        @cancel="hideSectinPicker"
        @saveSection="saveASection"
      ></exam-section-picker>
    </v-dialog>
    <v-dialog
      :key="editSectionPickerKye"
      v-model="isEditSectionPickerVisible"
      width="70%"
    >
      <exam-section-picker
        :questions="sectionData.questions"
        :existing-data="sectionData"
        :is-edit="true"
        @pickSectionQuestions="getSectionQuestions"
        @cancel="hideSectinPicker"
        @updateSection="updateASection"
      ></exam-section-picker>
    </v-dialog>
  </m-container>
</template>

<script>
import QuestionPicker from "#ecf/exam/components/QuestionPicker";
import ExamQuestionsTable from "#ecf/exam/components/ExamQuestionsTable";
import ExamSectionPicker from "#ecf/exam/components/ExamSectionPicker.vue";
import ExamSectionList from "#ecf/exam/components/ExamSectionList.vue";
import _ from "lodash";
export default {
  name: "ExamQuestionsForm",
  components: {
    QuestionPicker,
    ExamQuestionsTable,
    ExamSectionPicker,
    ExamSectionList
  },
  model: {
    prop: "questions"
  },
  props: {
    questions: {
      type: Array,
      default: () => []
    },
    totalPoints: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      formData: {
        questions: []
      },
      sections: [],
      sectionData: {
        questions: []
      },
      sectionIndexToUpdate: undefined,
      isQuestionPickerVisible: false,
      isSectionPickerVisible: false,
      isEditSectionPickerVisible: false,
      questionPickerKey: Math.random().toString(),
      sectionPickerKye: Math.random().toString(),
      editSectionPickerKye: Math.random().toString(),
      isSectionAdded: undefined,
      isQuestionAdded: undefined,
      isSectionQuestionPickerVisible: false,
      sectionQuestionPickerKey: Math.random().toString()
    };
  },
  computed: {
    sectionWithDurationCount() {
      let count = 0;
      this.sections.forEach(section => {
        if (section.duration !== undefined) {
          count++;
        }
      });
      return count;
    },
    combinedSectionPoints() {
      let allSectionTotalPoints = 0;
      for (let j = 0; j < this.sections.length; j++) {
        for (let i = 0; i < this.sections[j].questions.length; i++) {
          if (this.sections[j].questions[i].question_group) {
            for (
              let k = 0;
              k < this.sections[j].questions[i].questions.length;
              k++
            ) {
              allSectionTotalPoints += parseFloat(
                this.sections[j].questions[i].questions[k].points
              );
            }
          } else {
            allSectionTotalPoints += parseFloat(
              this.sections[j].questions[i].points
            );
          }
        }
      }
      return allSectionTotalPoints;
    },
    combinedQuestionsPoints() {
      let i = 0,
        countPoints = 0;
      for (let j = 0; j < this.formData.questions.length; j++) {
        if (this.formData.questions[j].question_group) {
          for (i = 0; i < this.formData.questions[j].questions.length; i++) {
            countPoints += parseFloat(
              this.formData.questions[j].questions[i].points
            );
          }
        } else {
          countPoints += parseFloat(this.formData.questions[j].points);
        }
      }
      return countPoints;
    }
  },
  watch: {
    "formData.questions": {
      deep: true,
      handler(questions) {
        this.isQuestionAdded = questions.length > 0;
        this.$emit("input", questions);
      }
    },
    sections: {
      handler(sections) {
        this.isSectionAdded = sections.length > 0;
        this.$emit("input", sections);
      }
    },
    totalPoints: {
      handler() {
        if (this.isSectionAdded) this.validateSectionMarks();
        else this.validateMarks();
      }
    }
  },
  mounted() {
    this.formData = {
      questions: this.questions || []
    };
    if (this.isSectionAdded) {
      this.validateSectionMarks();
    } else {
      this.validateMarks();
    }
  },
  methods: {
    validateSectionMarks() {
      if (parseInt(this.totalPoints) === this.combinedSectionPoints) {
        this.$emit("match", this.sections.length > 0);
      } else {
        this.$emit("match", false);
      }
    },
    validateMarks() {
      if (parseInt(this.totalPoints) === this.combinedQuestionsPoints) {
        this.$emit("match", this.formData.questions.length > 0);
      } else {
        this.$emit("match", false);
      }
    },
    addPoints({ groupIdx, questionIdx, points }) {
      if (questionIdx !== undefined) {
        this.$set(
          this.formData.questions[groupIdx].questions[questionIdx],
          "points",
          points
        );
        this.validateMarks();
      } else {
        this.$set(this.formData.questions[groupIdx], "points", points);
        this.validateMarks();
      }
    },
    showQuestionPicker() {
      this.questionPickerKey = Math.random().toString();
      this.isQuestionPickerVisible = true;
    },
    showSectionPicker() {
      this.sectionPickerKye = Math.random().toString();
      this.isSectionPickerVisible = true;
      this.sectionData.questions = [];
    },
    hideCategoryForm() {
      this.isQuestionPickerVisible = false;
    },
    hideSectinQuestionPicker() {
      // this.isSectionPickerVisible = true;
      this.isSectionQuestionPickerVisible = false;
    },
    hideSectinPicker() {
      this.isSectionPickerVisible = false;
      this.isEditSectionPickerVisible = false;
      // this.sectionData.questions = [];
    },
    hideUpdatedSectionPicker() {
      this.isEditSectionPickerVisible = false;
    },
    saveASection(sectionData) {
      this.sections.push(sectionData);
      this.hideSectinPicker();
      this.isSectionAdded = true;
      this.validateSectionMarks();
    },
    editASection(indexToUpdate) {
      this.editSectionPickerKye = Math.random().toString();
      this.isEditSectionPickerVisible = true;
      this.sectionData = this.sections[indexToUpdate];
      this.sectionIndexToUpdate = indexToUpdate;
    },
    updateASection(updatedData) {
      for (let key in this.sections[this.sectionIndexToUpdate]) {
        this.$set(
          this.sections[this.sectionIndexToUpdate],
          key,
          updatedData[key]
        );
      }
      this.hideSectinPicker();
      this.validateSectionMarks();
    },
    deleteCategory(i) {
      this.formData.questions.splice(i, 1);
      this.validateMarks();
    },
    addCategory(categories) {
      let i = 0;
      for (i = 0; i < categories.length; i++) {
        this.formData.categories.push(categories[i]);
      }
      this.hideCategoryForm();
    },
    getSectionQuestions() {
      this.isSectionQuestionPickerVisible = true;
    },
    updateSectionList(updatedList) {
      this.sections = updatedList;
      this.validateSectionMarks();
    }
  }
};
</script>

<style></style>
