<template>
  <m-container style="min-height: 70vh; " class="d-flex align-center">
    <m-container>
      <h1 class="mb-5 page-title">
        {{ !$language ? "Add Questions" : "প্রশ্ন যোগ করুন" }}
      </h1>

      <question-list
        :selectable="true"
        :selected.sync="questions"
        :category-search="true"
        hide-actions
      ></question-list>

      <v-container>
        <v-row justify="end">
          <v-btn outlined color="red accent-3" min-width="100" @click="close"
            >{{ !$language ? "Cancel" : "বাতিল করুন" }}
          </v-btn>
          <v-btn
            depressed
            color="primary"
            class="ml-3"
            min-width="100"
            @click="save"
            >{{ !$language ? "Save" : "সেভ করুন" }}
          </v-btn>
        </v-row>
      </v-container>
    </m-container>
  </m-container>
</template>

<script>
import QuestionList from "#ecf/question-bank/components/QuestionList";

export default {
  name: "QuestionPicker",
  components: { QuestionList },
  model: {
    prop: "selectedQuestions"
  },
  props: {
    selectedQuestions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      questions: this.selectedQuestions.map(q => ({
        ...q,
        preSelected: true
      }))
    };
  },
  watch: {
    selectedQuestions() {
      this.questions = this.selectedQuestions.map(q => ({
        ...q,
        preSelected: true
      }));
    }
  },
  methods: {
    save() {
      for (let i = 0; i < this.questions.length; i++) {
        if ("question_group" in this.questions[i]) {
          this.questions[i].questions = this.questions[i].questions?.map(q =>
            this.questions[i].preSelected
              ? q
              : { ...q, points: 1, order: undefined }
          );
        } else {
          if (!this.questions[i].preSelected) {
            this.questions[i].points = 1;
            this.questions[i].order = undefined;
          }
        }
      }
      this.$emit("input", this.questions);
      this.close();
    },
    close() {
      this.questions = [];
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss">
.page-title {
  font-family: "Poppins", "Helvetica", sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.5rem;
}
</style>
