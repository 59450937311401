<template>
  <v-container>
    <v-simple-table v-if="orderedIeltsQuestions">
      <thead>
        <tr>
          <th>{{ !$language ? "Question" : "প্রশ্ন" }}</th>
          <th>{{ !$language ? "Type" : "টাইপ" }}</th>
          <th>{{ !$language ? "Points" : "পয়েন্ট" }}</th>
          <th v-if="!readonly">{{ !$language ? "Actions" : "পরিবর্তন" }}</th>
        </tr>
      </thead>
      <tbody v-if="questions">
        <tr
          v-for="(question, idx) in orderedQuestions"
          :key="question.question_id"
        >
          <td>
            <div
              v-if="question.questionDetails.text"
              style="
                overflow: hidden;
                height: max-content;
              "
              class="ml-4 py-2"
              v-html="format(question.questionDetails.text)"
            ></div>
            <div v-else-if="question.questionDetails.file_url">
              <m-img
                :src="question.questionDetails.file_url"
                max-width="150"
                max-height="150"
                contain
              ></m-img>
            </div>
          </td>
          <td v-if="question.essay_id != null">
            {{ !$language ? "Group Question" : "গ্রুপ প্রশ্ন" }}
          </td>
          <td v-else>
            {{ !$language ? "Single Question" : "সিঙ্গেল প্রশ্ন" }}
          </td>
          <td>
            <v-text-field
              v-if="!readonly"
              :value="question.points"
              width="120px"
              label="points"
              type="text"
              @input="addPoints($event, idx)"
            ></v-text-field>
            <span v-else>{{ question.points }}</span>
          </td>
          <td>
            <v-btn v-if="!readonly" @click="deleteCategory(idx)"
              >{{ !$language ? "Delete" : "ডিলিট" }}
            </v-btn>
            <!-- todo: pass category as slot scope-->
            <slot name="rowActions"></slot>
          </td>
        </tr>
      </tbody>

      <tbody v-else-if="ieltsQuestions">
        <tr
          v-for="(question, idx) in orderedIeltsQuestions"
          :key="question.essay_id"
        >
          <td v-if="question.question_group">
            <question-group-viewer
              class="pa-2"
              :content="question.question_group.item_text"
              :questions="question.questions"
              :answers="question.answers"
              :files="question.files"
              :readonly="true"
            ></question-group-viewer>
          </td>
          <td v-else>
            <div
              v-if="question.questionDetails.text"
              style="
                overflow: hidden;
                height: max-content;
              "
              class="ml-4 py-2"
              v-html="format(question.questionDetails.text)"
            ></div>
            <div v-else-if="question.questionDetails.file_url">
              <m-img
                :src="question.questionDetails.file_url"
                max-width="150"
                max-height="150"
                contain
              ></m-img>
            </div>
          </td>
          <td v-if="question.essay_id != null">
            {{ !$language ? "Group Question" : "গ্রুপ প্রশ্ন" }}
          </td>
          <td v-else>
            {{ !$language ? "Single Question" : "সিঙ্গেল প্রশ্ন" }}
          </td>
          <td v-if="question.question_group">
            <div v-for="(questin, index) in question.questions" :key="index">
              <v-text-field
                v-if="!readonly"
                :value="questin.points"
                width="120px"
                label="points"
                type="text"
                @input="addPoints($event, idx, index)"
              ></v-text-field>
              <span v-else>{{ questin.points }}</span>
            </div>
          </td>
          <td v-else>
            <v-text-field
              v-if="!readonly"
              :value="question.points"
              width="120px"
              label="points"
              type="text"
              @input="addPoints($event, idx)"
            ></v-text-field>
            <span v-else>{{ question.points }}</span>
          </td>
          <td>
            <v-btn v-if="!readonly" @click="deleteCategory(idx)"
              >{{ !$language ? "Delete" : "ডিলিট" }}
            </v-btn>
            <!-- todo: pass category as slot scope-->
            <slot name="rowActions"></slot>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-container>
</template>

<script>
import katex from "katex/dist/katex.mjs";
import QuestionGroupViewer from "#ecf/question-bank/components/GroupQuestion/QuestionGroupViewer.vue";

const math = /\$([^$]*)\$/g;

export default {
  name: "ExamQuestionsTable",
  components: { QuestionGroupViewer },
  props: {
    questions: {
      type: Array,
      default: undefined
    },
    ieltsQuestions: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      categoryToView: this.categories,
      isCategoryViewerVisible: false,
      questionsArray: [],
      editorOptions: {
        modules: {
          toolbar: false
        }
      }
    };
  },
  computed: {
    orderedIeltsQuestions() {
      let temp = [...this.ieltsQuestions];
      temp.sort((x, y) => {
        return x.order - y.order;
      });
      return temp;
    }
  },
  methods: {
    format(text) {
      if (text.includes("$")) {
        return text.replace(math, (match, txt) => {
          const rendered = katex.renderToString(txt, {
            displayMode: true,
            leqno: false,
            fleqn: false,
            throwOnError: false,
            errorColor: "#cc0000",
            strict: "warn",
            macros: { "\\f": "f(#1)" }
          });
          return rendered;
        });
      } else {
        return text;
      }
    },
    addPoints(points, gidx, idx) {
      this.$emit("add-points", { groupIdx: gidx, questionIdx: idx, points });
    },
    viewCategory(category) {
      this.categoryToView = category;
      this.isCategoryViewerVisible = category;
    },
    deleteCategory(idx) {
      this.$emit("delete", idx);
    }
  }
};
</script>

<style lang="scss" scoped></style>
