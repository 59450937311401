// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".hr-style {\n  color: #6fc4e9;\n}\n@media screen and (min-width: 961px) and (max-width: 1903px) {\n.hr-style {\n    width: 100%;\n}\n}\n@media screen and (max-width: 960px) {\n.hr-style {\n    width: 100%;\n}\n}\n.single-tab-item {\n  text-transform: none !important;\n  color: #272727 !important;\n  opacity: 1;\n  font-size: 20px;\n}\n.single-tab-item::before {\n  border-radius: 6px 6px 0px 0px;\n}\n@media screen and (max-width: 599px) {\n.single-tab-item::before {\n    border-radius: 6px 6px 6px 6px;\n}\n}\n.selected-tab {\n  background-color: #0099dc;\n  border-radius: 6px 6px 0px 0px;\n  color: white !important;\n  opacity: 1;\n}\n.tab-bar {\n  border-bottom-color: #6fc4e9 !important;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
