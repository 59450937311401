<template>
  <v-form
    ref="form"
    :value="allValid"
    @input="$emit('update:all-valid', $event)"
  >
    <m-container class="pa-0">
      <m-row no-gutters>
        <m-col class="pa-0 mt-6">
          <m-text-field
            outlined
            :label="!$language ? 'Title' : 'শিরোনাম'"
            :placeholder="
              !$language
                ? 'Enter your exam title here...'
                : 'পরীক্ষার শিরোনাম লিখুন...'
            "
            height="45"
            :value="formData.title"
            :rules="rules.title"
            data-qa="title"
            @change="v => (formData.title = v)"
          ></m-text-field>
        </m-col>
        <m-col>
          <v-row no-gutters align-center style="height: 100%">
            <m-col class="d-flex justify-center align-center">
              <p class="xl:text-lg">Split view: &nbsp;</p>
              <img
                class="clickable"
                width="69"
                @click="toggleSplitView"
                :src="
                  require(isSplitView
                    ? '#ecf/exam/assets/Toggle-active.svg'
                    : '#ecf/exam/assets/Toggle-inactive.svg')
                "
              />
            </m-col>
            <m-col class="d-flex justify-end align-center">
              <p class="xl:text-lg">Practice exam: &nbsp;</p>
              <img
                class="clickable"
                width="69"
                @click="togglePracticeExam"
                :src="
                  require(isPracticeExam
                    ? '#ecf/exam/assets/Toggle-active.svg'
                    : '#ecf/exam/assets/Toggle-inactive.svg')
                "
              />
            </m-col>
          </v-row>
        </m-col>
      </m-row>
      <m-row no-gutters>
        <m-col class="pa-0">
          <m-textarea
            outlined
            auto-grow
            clearable
            clearable-icon="close"
            :label="!$language ? 'Instruction' : 'নির্দেশনা'"
            counter="1024"
            maxlenght="1024"
            :rules="rules.instruction"
            :placeholder="
              !$language
                ? 'Enter your exam instructions here...'
                : 'পরীক্ষার নির্দেশনা দিন...'
            "
            :value="formData.instruction"
            data-test="exam-text"
            @change="v => (formData.instruction = v)"
          ></m-textarea>
        </m-col>
      </m-row>
      <v-row align="center">
        <m-col cols="5">
          <m-text-field
            outlined
            :label="!$language ? 'Duration' : 'সময়কাল'"
            :placeholder="
              !$language
                ? 'Enter your exam duration here...'
                : 'পরীক্ষার সময়কাল লিখুন'
            "
            height="45"
            :rules="rules.examDurationRule"
            :value="formData.duration"
            data-qa="duration"
            @change="v => (formData.duration = v)"
          ></m-text-field>
        </m-col>
        <m-col cols="5">
          <m-text-field
            outlined
            :label="!$language ? 'Points' : 'পয়েন্টস'"
            :placeholder="
              !$language
                ? 'Enter your exam points here...'
                : 'পরীক্ষার পয়েন্টস লিখুন'
            "
            height="45"
            :rules="rules.examTotalPointsRule"
            :value="formData.points"
            data-qa="points"
            @blur="$emit('totalPointsChange')"
            @change="v => (formData.points = v)"
          ></m-text-field>
        </m-col>
      </v-row>
    </m-container>
  </v-form>
</template>

<script>
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "ExamDetailsForm",
  model: {
    prop: "examDetails"
  },
  mixins: [FieldValidations],
  props: {
    examDetails: {
      type: Object,
      default: () => ({})
    },
    allValid: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      formData: {
        view: "single",
        for_practice: false
      },
      isSplitView: false,
      isPracticeExam: false
    };
  },
  computed: {
    rules() {
      return {
        instruction: [
          v => this.required(v?.trim()),
          v => this.minLength(v?.trim(), 10, "instruction"),
          v => this.maxLength(v?.trim(), 1024, "instruction")
        ],
        title: [
          v => this.required(v?.trim()),
          v => this.minLength(v?.trim(), 3),
          v => this.maxLength(v?.trim(), 64)
        ],
        required: [this.required],
        examTotalPointsRule: [
          this.required,
          v => this.minValue(v, 1),
          v => this.digitsOnly(v)
        ],
        examDurationRule: [this.required, v => this.betweenValue(v, 1, 999)]
      };
    }
  },
  watch: {
    formData: {
      deep: true,
      handler(value) {
        this.$emit("input", value); // whenever formdata of this file changes, the exam-details to be changed is updated in
      }
    },
    isSplitView: {
      deep: true,
      handler(value) {
        this.formData.view = value ? "split" : "single";
      }
    },
    isPracticeExam: {
      deep: true,
      handler(value) {
        this.formData.for_practice = value;
      }
    }
  },
  mounted() {
    if (this.examDetails) {
      this.formData = { ...this.examDetails };
    }
  },
  methods: {
    toggleSplitView() {
      this.isSplitView = !this.isSplitView;
    },

    togglePracticeExam() {
      this.isPracticeExam = !this.isPracticeExam;
    },
    async validate() {
      this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss">
.font-size-20 {
  font-size: 20px;
}
</style>
