var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pb-1 ma-0 border-solid border-primary",
      attrs: { "min-height": "720", width: "100%" },
    },
    [
      _c(
        "div",
        { staticClass: "pa-4", staticStyle: { "max-width": "1100px" } },
        [
          _c("submission-alert-message", {
            ref: "banner",
            attrs: { "should-scroll-to-top": "", message: _vm.badMessage },
            model: {
              value: _vm.showBadMassage,
              callback: function ($$v) {
                _vm.showBadMassage = $$v
              },
              expression: "showBadMassage",
            },
          }),
        ],
        1
      ),
      _c("h2", { staticClass: "py-4 pl-6" }, [
        _vm._v(_vm._s(_vm.isEdit ? `Edit section` : `Add a section`)),
      ]),
      _c("div", {
        staticClass: "border-solid border-primary border-0 border-t",
      }),
      _c(
        "v-form",
        { ref: "sectionForm" },
        [
          _c(
            "v-row",
            { staticClass: "pt-5 px-6", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "pr-4" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Section Title",
                      rules: _vm.rules.title,
                      placeholder: "title of the section",
                      outlined: "",
                    },
                    model: {
                      value: _vm.sectionData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.sectionData, "title", $$v)
                      },
                      expression: "sectionData.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              rules: _vm.rules.sectionDurationRule,
                              label: "Duration",
                              placeholder: "Duration",
                            },
                            model: {
                              value: _vm.sectionData.duration,
                              callback: function ($$v) {
                                _vm.$set(_vm.sectionData, "duration", $$v)
                              },
                              expression: "sectionData.duration",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "d-flex justify-end pt-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { elevation: "0", color: "#D6F3FF" },
                              on: { click: _vm.showQuestionPicker },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("#ecf/course-design/assessts/select_content_icon.svg"),
                                },
                              }),
                              _vm._v("   "),
                              _c("p", { staticClass: "primary--text" }, [
                                _vm._v("Select questions"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-6", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("m-textarea", {
                    attrs: {
                      outlined: "",
                      "auto-grow": "",
                      clearable: "",
                      "clearable-icon": "close",
                      label: "Instruction",
                      counter: "1024",
                      maxlenght: "1024",
                      rules: _vm.rules.instruction,
                      placeholder: "Enter section instructions here...",
                      value: _vm.sectionData.instruction,
                      "data-test": "exam-text",
                    },
                    on: { change: (v) => (_vm.sectionData.instruction = v) },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "min-height": "400px" } },
        [
          _c("exam-questions-table", {
            attrs: { "ielts-questions": _vm.questions },
            on: {
              "add-points": _vm.addPoints,
              "validate-marks": _vm.validateMarks,
              delete: _vm.deleteCategory,
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "px-6 py-4",
          attrs: { "align-end": "", "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { outlined: "", color: "red" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("Cancel")]
              ),
              _vm.isEdit
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.updateSection },
                    },
                    [_vm._v(_vm._s(`Update`))]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.saveSection },
                    },
                    [_vm._v(_vm._s(`Save`))]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }