var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-content",
    { staticClass: "pa-0" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "elevation-0 pa-0 ma-0" },
            [
              _c(
                "v-list",
                {
                  staticClass: "pa-0 mt-6",
                  staticStyle: {
                    "min-height": "300px",
                    overflow: "hidden",
                    "scroll-behavior": "smooth",
                  },
                  attrs: { "two-line": "" },
                },
                [
                  _c(
                    "draggable",
                    {
                      model: {
                        value: _vm.sections,
                        callback: function ($$v) {
                          _vm.sections = $$v
                        },
                        expression: "sections",
                      },
                    },
                    [
                      _vm._l(_vm.sections, function (section, i) {
                        return [
                          _c("exam-section", {
                            key: i,
                            attrs: { "form-data": section },
                            on: {
                              onDeleteSection: function ($event) {
                                return _vm.remove(i)
                              },
                              onEditSection: function ($event) {
                                return _vm.edit(i)
                              },
                              getSectionTotalPoints: _vm.calculatePoints,
                            },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }