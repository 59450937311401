<template>
  <m-container class="pa-0">
    <div class="pb-4" style="max-width: 1100px">
      <submission-alert-message
        ref="banner"
        v-model="showAlertCondition"
        should-scroll-to-top
        :message="
          !sectionDurationMissingMessage
            ? badExamFormMessage[$language]
            : sectionDurationMissingMessage
        "
      ></submission-alert-message>
    </div>
    <v-card class="m-light-card pa-6 border-default-primary-solid">
      <v-tabs v-model="currentStep">
        <v-tab
          class="single-tab-item xl:text-xl lg:text-xl"
          active-class="selected-tab"
        >
          {{ !$language ? "Details" : "বিস্তারিত" }}
        </v-tab>
        <v-tab
          class="single-tab-item xl:text-xl lg:text-xl"
          active-class="selected-tab"
        >
          {{ !$language ? "Questions" : "প্রশ্ন" }}
        </v-tab>
      </v-tabs>
      <hr class="hr-style" />
      <v-tabs-items v-model="currentStep">
        <v-tab-item>
          <exam-details-form
            ref="details"
            v-model="examData['examDetails']"
            :all-valid.sync="examDetailsOk"
            data-qa="exam-details-form"
            @total-points-change="
              v => {
                examData.examDetails.points = v;
              }
            "
          ></exam-details-form>
          <v-btn
            color="primary"
            width="86"
            height="46"
            class="white--text xl:text-lg mb-1"
            @click="nextStep"
          >
            Next
          </v-btn>
        </v-tab-item>

        <v-tab-item>
          <exam-questions-form
            v-model="examData.questions"
            :total-points.sync="examData.examDetails.points"
            @match="updatePointsMatch"
          ></exam-questions-form>
          <v-btn outlined class="mt-4 mb-1" @click="previousStep">
            Back
          </v-btn>
          <v-btn
            v-if="
              examData.questions !== undefined && examData.questions.length > 0
            "
            color="primary"
            class="mt-4 ml-4 mb-1 white--text"
            @click="save"
          >
            <v-progress-circular
              v-if="creating"
              indeterminate
              color="white"
            ></v-progress-circular>
            <p v-else>Save</p>
          </v-btn>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </m-container>
</template>

<script>
import ExamQuestionsForm from "#ecf/exam/components/ExamQuestionsForm";
import ExamDetailsForm from "../components/ExamDetailsForm";
import { Logger } from "/global/utils/helpers";
import { EquationFinder } from "#ecf/question-bank/mixins";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";
import _ from "lodash";

export default {
  name: "ExamCreateForm",
  components: {
    ExamQuestionsForm,
    ExamDetailsForm,
    SubmissionAlertMessage
  },
  mixins: [EquationFinder],
  props: {
    exam: {
      type: Object,
      default: () => ({})
    },
    courseId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      examData: {},
      creating: false,
      currentStep: 0,
      examDetailsOk: undefined,
      pointsMatch: false,
      // sectionDurationMissing: false,
      sectionDurationMissingMessage: undefined,
      showAlertCondition: false
    };
  },
  inject: ["$courseOwnerId", "$courseId"],
  computed: {
    allowSaveCondition() {
      return this.examDetailsOk && this.pointsMatch;
    },
    badExamFormMessage() {
      if (this.examDetailsOk && !this.pointsMatch) {
        return {
          false: "Sum of all question weight do not match Total Points",
          true: "পসকল প্রশ্নের মানের যোগফল পরীক্ষার মানের সমান হয় নি"
        };
      } else {
        return {
          false: "Fill out all mandatory fields",
          true: "সকল প্ৰয়োজনীয় তথ্য পূরণ করুন"
        };
      }
    },
    isSectionExam() {
      return !!this.examData?.questions?.at(0)?.title;
    },
    sectionDurationMissing() {
      let count = 0;
      if (this.isSectionExam) {
        this.examData?.questions?.forEach(section => {
          if (section.duration !== undefined) count++;
        });
        return count !== this.examData?.questions?.length ?? -1;
      } else return false;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        if (newVal.meta && newVal.meta.page) {
          this.currentStep = newVal.meta.page;
        }
      }
    },
    allowSaveCondition: {
      handler(value) {
        if (value) this.showAlertCondition = false;
      }
    }
  },
  async beforeMount() {
    let modifiedQuestions = this.exam.questions;
    await this.retrieveEquationsAndModifyQuestions(modifiedQuestions);
    this.examData = {
      examDetails: {
        ...(this.exam.id ? { id: this.exam.id } : {}),
        title: this.exam.title,
        instruction: this.exam.instruction,
        duration: this.exam.duration,
        points: this.exam.points,
        view: this.exam.view,
        for_practice: this.exam.for_practice
      },
      questions: modifiedQuestions || []
    };
    if (this.examData.questions.length > 0) this.pointsMatch = true;
  },
  methods: {
    updatePointsMatch(value) {
      this.pointsMatch = value;
    },
    nextStep() {
      if (this.currentStep !== 1) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep !== 0) {
        this.currentStep--;
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    async save() {
      this.sectionDurationMissingMessage = undefined;
      await this.$refs.details.validate();
      if (!this.allowSaveCondition) {
        if (!this.showAlertCondition) this.showAlertCondition = true;
        else {
          await this.$refs.banner.flash();
        }
        return;
      }
      if (this.creating) return;
      try {
        this.creating = true;

        if ("title" in this.examData.questions[0]) {
          for (let sec = 0; sec < this.examData.questions.length; sec++) {
            if (this.sectionDurationMissing) {
              if (this.examData.questions[sec].duration === undefined) {
                this.sectionDurationMissingMessage = `Duration not given in ${this.examData.questions[sec].title}`;
                if (!this.showAlertCondition) this.showAlertCondition = true;
                return;
              }
            } else this.showAlertCondition = false;
            let quest = [];
            for (
              let i = 0;
              i < this.examData.questions[sec].questions.length;
              i++
            ) {
              if (this.examData.questions[sec].questions[i].question_group) {
                let groupQuestions = this.examData.questions[sec].questions[i];
                for (let j = 0; j < groupQuestions.questions.length; j++) {
                  quest.push(groupQuestions.questions[j]);
                }
              } else {
                this.examData.questions[sec].questions[
                  i
                ].id = this.examData.questions[sec].questions[i].question_id;
                this.$delete(
                  this.examData.questions[sec].questions[i],
                  "question_id"
                );
                quest.push(this.examData.questions[sec].questions[i]);
              }
            }
            this.$set(this.examData.questions[sec], "questions", quest);
          }
          this.examData.type = "section";
          await this.$store.dispatch("exam/create", {
            exam: this.examData,
            courseId: this.courseId,
            owner_id: this.$courseOwnerId()
          });
          this.$emit("done");
        } else {
          let quest = [];
          for (let i = 0; i < this.examData.questions.length; i++) {
            if (this.examData.questions[i].question_group) {
              for (
                let j = 0;
                j < this.examData.questions[i].questions.length;
                j++
              ) {
                quest.push(this.examData.questions[i].questions[j]);
              }
            } else {
              this.examData.questions[i].id = this.examData.questions[
                i
              ].question_id;
              this.$delete(this.examData.questions[i], "question_id");
              quest.push(this.examData.questions[i]);
            }
          }
          this.examData.questions = quest;
          await this.$store.dispatch("exam/create", {
            exam: this.examData,
            courseId: this.courseId,
            owner_id: this.$courseOwnerId()
          });
          this.$emit("done");
        }
      } catch (e) {
        Logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.creating = false;
      }
    }
  }
};
</script>

<style lang="scss">
.hr-style {
  color: #6fc4e9;
  @media screen and (min-width: 961px) and (max-width: 1903px) {
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
}

.single-tab-item {
  text-transform: none !important;
  color: #272727 !important;
  opacity: 1;
  font-size: 20px;
}

.single-tab-item::before {
  border-radius: 6px 6px 0px 0px;
  @media screen and (max-width: 599px) {
    border-radius: 6px 6px 6px 6px;
  }
}

.selected-tab {
  background-color: $primary;
  border-radius: 6px 6px 0px 0px;
  color: white !important;
  opacity: 1;
}

.tab-bar {
  border-bottom-color: #6fc4e9 !important;
}
</style>
