<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header style="border: #b7b7b7 1px solid">
          <v-card flat>
            <v-row no-gutters align="center" style="height: 60px">
              <v-col class="d-flex justify-start align-center">
                <img :src="require('#ecf/exam/assets/moveable.svg')" />
                &nbsp;{{ formData.title }}
              </v-col>
              <v-col class="d-flex justify-end align-center">
                <div>
                  <v-icon class="mr-2" @click="editSection"
                    >mdi-pencil-outline</v-icon
                  >
                </div>
                <div>
                  <v-icon class="mr-1" color="red" @click="deleteSection"
                    >mdi-delete</v-icon
                  >
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="border: #b7b7b7 1px solid">
          <exam-questions-table
            :ielts-questions="formData.questions"
            @add-points="addPoints"
            @validate-marks="validateMarks"
            @delete="deleteCategory"
          >
          </exam-questions-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ExamQuestionsTable from "#ecf/exam/components/ExamQuestionsTable.vue";

export default defineComponent({
  components: { ExamQuestionsTable },
  props: ["formData"],
  methods: {
    addPoints({ groupIdx, questionIdx, points }) {
      if (questionIdx !== undefined) {
        this.$set(
          this.formData.questions[groupIdx].questions[questionIdx],
          "points",
          points
        );
        this.validateMarks();
      } else {
        this.$set(this.formData.questions[groupIdx], "points", points);
        this.validateMarks();
      }
    },
    validateMarks() {
      let i = 0,
        sectionPoints = 0;
      for (let j = 0; j < this.formData.questions.length; j++) {
        if (this.formData.questions[j].question_group) {
          for (i = 0; i < this.formData.questions[j].questions.length; i++) {
            sectionPoints += parseFloat(
              this.formData.questions[j].questions[i].points
            );
          }
        } else {
          sectionPoints += parseFloat(this.formData.questions[j].points);
        }
      }
      this.$emit("getSectionTotalPoints", sectionPoints);
    },
    deleteCategory(i) {
      this.formData.questions.splice(i, 1);
      this.validateMarks();
    },
    editSection() {
      this.$emit("onEditSection");
    },
    deleteSection() {
      this.$emit("onDeleteSection");
    }
  }
});
</script>

<style></style>
