<template>
  <v-card
    class="pb-1 ma-0 border-solid border-primary"
    min-height="720"
    width="100%"
  >
    <div class="pa-4" style="max-width: 1100px">
      <submission-alert-message
        ref="banner"
        v-model="showBadMassage"
        should-scroll-to-top
        :message="badMessage"
      ></submission-alert-message>
    </div>
    <h2 class="py-4 pl-6">{{ isEdit ? `Edit section` : `Add a section` }}</h2>
    <div class="border-solid border-primary border-0 border-t" />
    <v-form ref="sectionForm">
      <v-row no-gutters class="pt-5 px-6">
        <v-col class="pr-4">
          <v-text-field
            v-model="sectionData.title"
            label="Section Title"
            :rules="rules.title"
            placeholder="title of the section"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="sectionData.duration"
                outlined
                :rules="rules.sectionDurationRule"
                label="Duration"
                placeholder="Duration"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-end pt-3">
              <v-btn elevation="0" color="#D6F3FF" @click="showQuestionPicker"
                ><img
                  :src="
                    require('#ecf/course-design/assessts/select_content_icon.svg')
                  "
                />
                &nbsp;
                <p class="primary--text">Select questions</p></v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="px-6" no-gutters>
        <v-col>
          <m-textarea
            outlined
            auto-grow
            clearable
            clearable-icon="close"
            label="Instruction"
            counter="1024"
            maxlenght="1024"
            :rules="rules.instruction"
            placeholder="Enter section instructions here..."
            :value="sectionData.instruction"
            data-test="exam-text"
            @change="v => (sectionData.instruction = v)"
          ></m-textarea>
        </v-col>
      </v-row>
    </v-form>

    <div style="min-height: 400px">
      <exam-questions-table
        :ielts-questions="questions"
        @add-points="addPoints"
        @validate-marks="validateMarks"
        @delete="deleteCategory"
      ></exam-questions-table>
    </div>
    <v-row align-end class="px-6 py-4" no-gutters>
      <v-col>
        <v-btn class="mr-4" outlined color="red" @click="cancel">Cancel</v-btn>
        <v-btn v-if="isEdit" color="primary" @click="updateSection">{{
          `Update`
        }}</v-btn>
        <v-btn v-else color="primary" @click="saveSection">{{ `Save` }}</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ExamQuestionsTable from "#ecf/exam/components/ExamQuestionsTable";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";
import { FieldValidations } from "/global/utils/validations.js";
import _ from "lodash";
export default {
  name: "ExamSectionPicker",
  components: { SubmissionAlertMessage, ExamQuestionsTable },
  mixins: [FieldValidations],
  props: ["questions", "isEdit", "existingData"],
  data() {
    return {
      sectionData: {
        title: "",
        duration: undefined,
        instruction: "",
        questions: []
      },
      isQuestionPickerVisible: false,
      badMessage: "Fill up the required field correctly",
      showBadMassage: false,
      questionPickerKey: Math.random().toString()
    };
  },
  computed: {
    rules() {
      return {
        instruction: [
          v => this.required(v?.trim()),
          v => this.minLength(v?.trim(), 10, "instruction"),
          v => this.maxLength(v?.trim(), 1024, "instruction")
        ],
        title: [
          v => this.required(v?.trim()),
          v => this.minLength(v?.trim(), 3),
          v => this.maxLength(v?.trim(), 64)
        ],
        required: [this.required],
        sectionDurationRule: [v => this.betweenValue(v, 1, 999)]
      };
    }
  },
  created() {
    if (this.isEdit) {
      this.sectionData = _.cloneDeep(this.existingData);
    }
  },
  methods: {
    addPoints({ groupIdx, questionIdx, points }) {
      if (questionIdx !== undefined) {
        this.$set(
          this.questions[groupIdx].questions[questionIdx],
          "points",
          points
        );
        this.validateMarks();
      } else {
        this.$set(this.questions[groupIdx], "points", points);
        this.validateMarks();
      }
    },
    validateMarks() {
      let i = 0,
        sectionPoints = 0;
      for (let j = 0; j < this.questions.length; j++) {
        if (this.questions[j].question_group) {
          for (i = 0; i < this.questions[j].questions.length; i++) {
            sectionPoints += parseFloat(this.questions[j].questions[i].points);
          }
        } else {
          sectionPoints += parseFloat(this.questions[j].points);
        }
      }
      this.$emit("getSectionTotalPoints", sectionPoints);
    },
    deleteCategory(i) {
      this.questions.splice(i, 1);
      this.validateMarks();
    },
    showQuestionPicker() {
      this.$emit("pickSectionQuestions");
    },
    cancel() {
      this.$emit("cancel");
    },
    saveSection() {
      if (this.$refs.sectionForm.validate()) {
        this.sectionData.questions = this.questions;
        this.$emit("saveSection", this.sectionData);
      } else {
        this.showBadMassage = true;
      }
    },
    updateSection() {
      if (this.$refs.sectionForm.validate()) {
        this.sectionData.questions = this.questions;
        this.$emit("updateSection", this.sectionData);
      } else {
        this.showBadMassage = true;
      }
    }
  }
};
</script>

<style></style>
